$alert-info-bg: #cfebff;
$alert-info-border: #94bedb;
$alert-info-text: #2a6891;
$alert-success-bg: #b2ede8;
$alert-success-border: #63c8be;
$alert-success-text: #0a776c;
$alert-warning-bg: #fcf4da;
$alert-warning-text: #91792f;
$alert-warning-border: #cbb677;
$alert-danger-bg: #fddede;
$alert-danger-border: #cb7c7a;
$alert-danger-text: #933230;
$gray-base: #000;
$gray-lighter: lighten($gray-base, 93.5%); // #eee
$gray-light: lighten($gray-base, 46.7%); // #777
$gray: lighten($gray-base, 33.5%); // #555
$gray-dark: lighten($gray-base, 20%); // #333
$gray-darker: lighten($gray-base, 13.5%); // #222
$red: #db5565;

$text-color: $gray-dark;
$body-bg: #f2f2f2;

$fa-font-path: "/fonts";
$font-family: "GT Walsheim Pro";

html, body {
  height: 100%;
}

* {
  box-sizing: border-box;
  outline: medium none !important;
}

*, :after, :before {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  background: $body-bg;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  position: relative;
  font-family: "GT Walsheim Pro","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
}

a {
  background-color: transparent;
  color: #0bacd3;
  text-decoration: none;
  cursor: pointer;
}

a:active, a:hover {
  outline: 0;
}

a:focus, a:hover {
  color: #07718a;
  text-decoration: underline;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input {
  line-height: normal;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button, input, optgroup, select, textarea {
  font: inherit;
  color: inherit;
}

hr {
  box-sizing: content-box;
  height: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

img {
  border: 0;
  vertical-align: middle;
}

@font-face {
  font-family: $font-family;
  src: url('#{$fa-font-path}/GTWalsheimPro/GT-Walsheim-Pro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.validation-error-msg {
  margin-top: 5px;
  padding-left: 3px;
  color: $red;
  font-size: 13px;
  font-weight: normal;
}

.alert {
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 10px 10px 9px 15px;

  .close {
    opacity: 1;
    margin-top: -3px;
    margin-right: 5px;
    text-shadow: none;
  }

  > i {
    margin-right: 8px;
    margin-top: 2px;
    float: left;

    &.glyphicon {
      margin-top: -3px;
    }
  }

  &.alert-warning {
    background-color: $alert-warning-bg;
    border-color: $alert-warning-border;
    color: $alert-warning-text;

    .close {
      color: $alert-warning-text;

      &:hover,
      &:focus {
        color: darken($alert-warning-text, 5%);
      }
    }
  }

  &.alert-success {
    background-color: $alert-success-bg;
    border-color: $alert-success-border;
    color: $alert-success-text;

    .close {
      color: $alert-success-text;

      &:hover,
      &:focus {
        color: darken($alert-success-text, 5%);
      }
    }
  }

  &.alert-danger {
    background-color: $alert-danger-bg;
    border-color: $alert-danger-border;
    color: $alert-danger-text;

    .close {
      color: $alert-danger-text;

      &:hover,
      &:focus {
        color: darken($alert-danger-text, 5%);
      }
    }
  }

  &.alert-info {
    background-color: $alert-info-bg;
    border-color: $alert-info-border;
    color: $alert-info-text;

    .close {
      color: $alert-info-text;

      &:hover,
      &:focus {
        color: darken($alert-info-text, 5%);
      }
    }
  }
}
